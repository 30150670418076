html,
body,
#root {
  margin: unset;
  padding: unset;
  height: 100%;
}

* {
  margin: 0;
  padding: 0;
}

#root {
  display: flex;
  height: 100%;
  flex-direction: column;

  .str-chat-channel-list {
    position: fixed;
    z-index: 1;
    width: 0;

    &--open {
      width: 100%;
    }
  }

  .str-chat-channel {
    width: 100%;
  }

  .str-chat__thread {
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: 1;
  }

  .str-chat__channel-header .str-chat__header-hamburger {
    width: 30px;
    height: 38px;
    padding: var(--xxs-p);
    margin-right: var(--xs-m);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    border: none;
    background: transparent;

    &:hover {
      svg path {
        fill: var(--primary-color);
      }
    }
  }

  @media screen and (min-width: 768px) {
    .str-chat-channel-list {
      width: 30%;
      max-width: 420px;
      position: initial;
      z-index: 0;
    }

    .str-chat__thread {
      position: initial;
      z-index: 0;
    }
  }

  @media screen and (min-width: 1024px) {
    .str-chat__thread {
      width: 45%;
    }

    .str-chat__channel-header .str-chat__header-hamburger {
      display: none;
    }
  }
}

.str-chat__container {
  display: flex;
  flex-direction: column;
}

.str-chat__ul {
  display: flex;
  flex-direction: column;
  align-items: end;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}
